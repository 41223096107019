<template>
    <h2>comentarios</h2>
    <div :class="{ 'contenedor': true, 'expandido': expanded }" v-show="existComm > 0">
        <div v-for="(item, index) in jcomments">
            <div class="container-list mt-2">
                <div class="fixed-width">
                    <img class="w-4 sm:w-16rem xl:w-4rem block xl:block mx-auto border-round" src="../assets/img/savatar.png" :alt="item.name" />
                </div>
                <div class="flexible">
                    <Rating :modelValue="item.puntuation" readonly :cancel="false"></Rating>
                    <div class="text-2xl font-bold text-900">{{ item.name }}</div>
                    <div class="text-700" style="font-size: 18px;">{{ item.comment }}</div>
                    
                </div>
                <div class="fixed-width">
                    <span>{{ item.date }}</span>
                </div>
            </div>
        </div>
    </div>
    <div v-show="existComm <= 0" class="mt-4">
      <div class="justify-center">
        <p style="font-size: 25px;">No existen comentarios actuales</p>
      </div>
    </div>
    <br>
    <a class="btn-comment" @click="toggleExpansion" v-show="toggleView">{{ btnMessage }}</a>
</template>

<script>
import { sliderService } from '@/services/sliderService.js';
import { ref } from 'vue';
import { ApiService } from '@/services/ApiService';
import { DateService } from '@/services/DateService'
export default {
    props:{
        id_number: Number,
        name_id: String,
    },
    components:{},
    data(){
        return{
            jcomments : ref(),
            toggleView: false,
            expanded: false,
            btnMessage: 'Ver más',
            existComm: 0,
        }
    },
    mounted: function(){
        this.getData();
    },
    watch:{
        'id_number':function(newData, oldData){
            this.getData();
        }
    },
    methods:{
        async getData(){
          console.log(this.name_id + " " +this.id_number);
          try {
              await ApiService.get("/comments?name_id=" + this.name_id + "&id_number=" + this.id_number + "")//Llamamos al Service de las apis para traer datos en get
                    .then(async data => {
                        if (data.length > 0) {
                            data = await DateService.updateDate(data);
                            console.log(data);
                            this.jcomments = data;
                            this.toggleView = this.jcomments.length > 2;
                            this.existComm = data.length;
                        }
                    })
                    .catch(error => {
                      console.error('Error en la solicitud:', error.message);
                    });
          } catch (error) {
              console.error('Error en la solicitud:', error.message);
          }
      },
      toggleExpansion() {
          this.expanded = !this.expanded;
          this.btnMessage = this.expanded == true ? 'Ver menos' : "Ver más";
      },
    },
}
</script>

<style scoped>
.contenedor {
  height: 230px; /* Tamaño inicial */
  overflow: hidden;
  position: relative;
  transition: width 0.3s ease; /* Transición suave */
}

.btn-comment {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-comment:hover {
  background: rgba(29, 29, 29, 0.5);
}

.expandido {
  height: auto; /* Tamaño expandido */
}

.container-list {
  width: 100%;
  background: none;
  display: flex;
  flex-direction: column; /* Change flex direction for screen width below 700px */
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
  border-bottom: 3px solid rgba(224, 224, 224, 0.418);
}

.container-list:hover {
  background: rgba(140, 140, 140, 0.185);
}

.fixed-width {
  width: 200px;
  text-align: center;
  padding: 10px;
}

.flexible {
  flex: 1; /* Se adapta al tamaño disponible */
  text-align: start;
  padding: 10px;
}

@media (min-width: 1200px) {
  .container-list {
    flex-direction: row; /* Change flex direction back to row for screen width above 700px */
  }
}
</style>