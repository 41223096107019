<template>
    <div v-for="items in infoP" class="mb-2">
		<BannerBlue :title="items.title"/>
		<Toast />
		<section class="s-single-product">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-5">
						<img :src="items.image || 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'" :alt="items.model" style="width: 100%; filter: brightness(1.1); mix-blend-mode: multiply;" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'">
					</div>
					<div class="col-12 col-md-7 single-shop-left">
						<h2 class="title">{{ items.title }}</h2>
						<div class="single-price" v-if="false">
							<div class="new-price" v-if="!items.promotion">${{ (items.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN</div>
							<div class="new-price" v-if="items.promotion">${{ (items.promotion).toFixed(2) }} MXN</div>
							<div class="old-price" v-if="items.promotion">${{ (items.cost).toFixed(2) }} MXN</div>
						</div>
						<p class="infoProd">
							{{ items.description }}
						</p>
						<p class="infoProd">
							<strong>Marca: </strong>{{ items.brand }}
						</p>
						<p class="infoProd">
							<strong>Modelo: </strong>{{ items.model }}
						</p>
						<p class="infoProd">
							<strong>SKU: </strong>{{ items.sku }}
						</p>
						<p class="infoProd">
							<strong>Código SAT : </strong>{{ items.code_sat }}
						</p>
						
						<div class="single-quanity infoProd">
							<div class="cantdiv">
								Cantidad:
								<button class="btn-plow" style="margin-left: 10px;" @click="lessprod" :disabled="isDisabled">
									<svg fill="#000000" width="30px" height="25px" viewBox="-6.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>hyphen</title> <path d="M13.875 12.906v2.281h-8.563v-2.281h8.563z"></path> </g></svg>
								</button>
								<input type="number" v-model="numberInput" @input="validateInput" :max="items.quantity" min="1" class="inpCantProd" />
								<button class="btn-plow" @click="plusprod">
									<svg width="20px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M12 4V20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
								</button>
							</div>
						</div>
						<div class="single-btn-cover">
							<a href="#" class="btn btn-buy-now" v-if="false"><span>comprar ahora</span></a>
							<a style="cursor: pointer;" class="btn btn-wishlist" @click="addCart">
								<span>
									<i class="pi pi-cart-plus"></i>
									añadir a la cotización
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Divider/>
		<div class="mt-5 container">
			<Comments :id_number="id_number" name_id="id_product" v-show="id_number>0"/>
			<br><br>
			<br>
			<Topsale :numVisible="4" class="mb-5"/>
		</div>
	</div>
</template>

<script>
import SliderProduct from '../components/SliderProduct.vue';
import BannerBlue from '@/components/BannerBlue.vue';
import Comments from '@/components/Comments.vue';
import Topsale from '@/components/Topsale.vue';
import { infoProduct } from '../services/infoProduct.js';
import { ApiService } from '@/services/ApiService.js';
import { ImageService } from '@/services/ImageService';
import { ref } from 'vue';
    export default {
        components:{
			SliderProduct,
			BannerBlue,
			Comments,
			Topsale,
		},
		props:{
			model: String,
			brand: String,
		},
		data(){
            return{
                infoP : ref({}),
				vproduct: ref({
					model: '',
					brand: '',
				}),
				numberInput : ref(1),
				isDisabled: true,
				id_number: 0,
            }
        },
		watch:{
			'$route.params.model': function(newData, oldData){
				this.getData(newData, this.$route.params.brand)
			},
			'$route.params.brand': function(newData, oldData){
				this.getData(this.$route.params.model, newData)
			}
		},
		async beforeMount(){
			//infoProduct.getAllDataProduct().then((data) => (this.infoP = data));
			//ApiService.get("/product", this.vproduct).then((data) => (this.infoP = data));//Ya está el llamado a la API
			this.vproduct.model = this.$route.params.model;
			this.vproduct.brand = this.$route.params.brand;
			this.getData(this.$route.params.model, this.$route.params.brand)
		},
		methods:{
			async getData(model, brand){
				model = model.replace('+', '/')
				await ApiService.get('/productView?brand='+ brand +'&model='+ model)//Llamamos al Service de las apis para traer datos en get
					.then(async data => {
						data = await ImageService.updateUrl(data); //Se llama el Service para actualizar el utl de la imagen 
						this.infoP = data;
						this.id_number = data[0].id;
					})
					.catch(error => {
						console.error('Error en la solicitud:', error.message);
					});
			},
			addCart(){
				var jProduct = {
					image : this.infoP[0].image,
					alt   : this.infoP[0].alt,
					title : this.infoP[0].title,
					model : this.infoP[0].model,
					brand : this.infoP[0].brand,
					//cost  : this.infoP[0].cost,
					//promotion : this.infoP[0].promotion,
					quantity  : this.numberInput,
				};
				this.$store.dispatch('findAndUpdateProduct', jProduct);
				//Toast libreria Simple Notify
				new Notify({
					status: 'success',
					title: 'Agregado',
					text: 'El producto '+this.infoP[0].title+' se ha agregado al carrito',
					effect: 'fade',
					speed: 300,
					customClass: null,
					customIcon: false,
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					gap: 20,
					distance: 20,
					type: 2,
					position: 'right top'
				})
				//console.log(this.$store.getters.getProducts);
			},
			validateInput(event) {
				const input = event.target;
				// Eliminar caracteres no numéricos y signos, permitiendo solo números enteros positivos.
				const sanitizedValue = input.value.replace(/[^0-9]/g, '');
				input.value = sanitizedValue;
				this.numberInput = sanitizedValue > this.infoP[0].quantity ? this.infoP[0].quantity : sanitizedValue;
			},
			tDisableBtn(){
				this.isDisabled = !this.isDisabled;
			},
			plusprod(){
				this.isDisabled = false;
				this.numberInput = this.numberInput + 1;
				if(this.numberInput > this.infoP[0].quantity) this.numberInput = this.infoP[0].quantity
			},
			lessprod(){
				this.numberInput = this.numberInput - 1;
				if (this.numberInput == 1){
					this.tDisableBtn()
				} 
			}
		}
    }
</script>

<style scoped>

hr{
	size:"1px";
	color:"black";
	border-style: dotted;
}
.cantdiv{
	display: flex;
	align-items: center;
	
}
.btn-plow {
    background: none;
	background: #EEC815;
    border: 2px solid #EEC815;
    padding: 2px;
	height: 2rem;
    cursor: pointer;
    width: 40px;
    border-radius: 2px;
    transition: background-color 0.5s ease;
}

.btn-plow:hover {
    background: rgba(247, 220, 88, 0.664);
}
.btnCant{
	font-size: 2rem;
	width: 1rem;
	height: 2.5rem;
	border-radius: 2px;
	margin: 0 10px;
	background: #EEC815;
}
.inpCantProd{
	width:3.5rem;
	height: 2rem;
	font-size: 1.4rem;
	text-align: center;
	border: 2px solid #EEC815;
}
.infoProd{
	text-align:start;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
</style>