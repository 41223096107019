<template>
    <div class="container">
		<h2 class="title mb-3">Recomendaciones</h2>
		<swiper
			:slidesPerView="1"
			:navigation="true"
			:loop="true"
			:spaceBetween="10"
			:autoplay="{
				delay: 2500,
				disableOnInteraction: false,
			}"
			:pagination="{
				dynamicBullets: true,
				clickable: true,
			}"
			:breakpoints="{
			'640': {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			'768': {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			'1024': {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			}"
			:modules="modules"
			class="mySwiper"
		>
			<swiper-slide v-for="(item, index) in imgSale">
				<div class="product-item" style="height: 550px; margin: 0 10px;" >
					<router-link :to="`/producto/${item.brand}/${item.model}`" @click="topSale">
						<a class="product-img"><img class="lazy " :src="item.image || 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'" :alt="item.sku" style="filter: brightness(1.1); mix-blend-mode: multiply;" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'"></a>
						<div class="product-item-cover">
							<div class="price-cover">
								<div class="new-price" v-if="false">${{ (item.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN</div>
								<div class="old-price" v-if="false">${{ (item.old_cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }}</div>
							</div>
							<h6 class="prod-title"><router-link :to="`/producto/${item.brand}/${item.model}`" @click="topSale">{{ item.title }}</router-link></h6>
							<div style="height: 110px; width: 100%;"><strong>Marca:</strong> {{ item.brand }} <strong>Modelo:</strong> {{ item.model }}
								<p style="text-align: justify; height: 110px; width: 100%; text-overflow: ellipsis; overflow: hidden;">{{ item.description }}</p>
							</div>
							
							<div style="background: #fff;">
								<router-link :to="`/producto/${item.brand}/${item.model}`" class="btn" @click="topSale"><span>ver</span></router-link>
							</div>
						</div>
					</router-link>
				</div>
			</swiper-slide>
		</swiper>

	</div>
</template>

<script>
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } 				from 'swiper/vue';
import { sliderService } 					from '@/services/sliderService';
import { ImageService } 					from '@/services/ImageService'
import { ApiService } 						from '@/services/ApiService';
import { ref } 								from 'vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import Carousel from 'primevue/carousel';
    export default {
        name: "Topsale",
        props:{
			numVisible: Number 
		},
        components:{
			Carousel,
			Swiper,
			SwiperSlide,
		},
        data(){
            return{
				modules: [Autoplay, Pagination, Navigation],
				valor: ref(true),
                imgSale : ref(),
				responsiveOptions: ref([
					{
						breakpoint: '1367px',
						numVisible: 3,
						numScroll: 1
					},
					{
						breakpoint: '991px',
						numVisible: 2,
						numScroll: 1
					},
					{
						breakpoint: '767px',
						numVisible: 1,
						numScroll: 1
					}
				]),
				topofertas:ref(),
            }
        },
		mounted: async function(){
			this.getApi();
		},
        methods:{
			topSale() {
				window.scrollTo(0, 0);
                this.$emit("topSale", this.valor); // Emitir un evento hacia el componente padre para mandar arriba y cerrar modal
                //sintaxis, ("nombre-de-funcion", variable-a-enviar)
            },
			async getApi(){
				await ApiService.get('/topsales')//Llamamos al Service de las apis para traer datos en get
					.then(async data => {
						console.log(data);
						if ('detail' in data){
							this.$router.push('/mantenimiento')
						}else{
							console.log(data);
							data = await ImageService.updateUrl(data); //Se llama el Service para actualizar el utl de la imagen 
							console.log(data);
							this.imgSale = data;
						}
						
					})
					.catch(error => {
						console.error('Error en la solicitud:', error.message);
					});
			}
        }
    }
</script>

<style scoped>

</style>