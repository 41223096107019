<template>
    <BannerBlue :title="nametitle"/>
    <Sidebar v-model:visible="siderFilters" header="Sidebar" position="top" class="w-full h-30rem">
        <div class="containerCart">
            <center><h4 class="mt-4 mb-2">Filtros</h4></center>
            <ul>
                <li class="widget wiget-shop-category">
                    <center>
                        <button 
                            class="btnProduct"
                            @click="aplyfilters"
                        > 
                        Aplicar filtros
                            <i class="pi pi-filter" style="color: #000;"></i>
                        </button>
                    </center>
                    <h5 class="title">marcas</h5>
                    <ul v-for="(item, index) in namebrands">
                        <li>
                            <div class="flex align-items-center">
                                <RadioButton v-model="brandfilter" :inputId="`brand${index}`" :name="item" :value="`${item['brand']}`" style="border-radius: 10px; border: 1px solid #ffbf00"/>
                                <label :for="`brand${index}`" class="ml-2">{{ item }}</label>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="widget wiget-price" v-if="false">
                    <h5 class="title">precio($)</h5>
                    <div class="amount-cover">
                        $<input type="text" placeholder="min" id="amount-min" v-model="rangeprice[0]" style="background: #fff; border: 2px solid #ffbf00">
                        <span>&mdash;</span>
                        <input type="text" placeholder="max" id="amount-max" v-model="rangeprice[1]" style="background: #fff; border: 2px solid #ffbf00">
                    </div>
                </li>
            </ul>
            <center><a class="reset-filter-btn" @click="resetfilters" style="cursor: pointer;">Reestablecer filtros</a></center>
        </div>
    </Sidebar>
    <section class="s-shop">
        <div class="container">
            <div class="btn" style="width: 100%; cursor: pointer;" v-show="viewportWidth < 1023" @click="siderFilters = true"><span>filtros</span></div>
            <div class="container-products" v-show="viewportWidth > 1023">
                <div class="filters-panel" >
                    <!-- Contenido del panel izquierdo -->
                    <div class="col-12 shop-sidebar">
                        <ul class="widgets wigets-shop">
                            <li class="widget wiget-shop-category">
                                <button 
                                    class="btnProduct mb-2"
                                    @click="aplyfilters"
                                > 
                                Aplicar
                                    <i class="pi pi-filter" style="color: #000;"></i>
                                </button>
                                <h5 class="title" style="font-family: Verdana;">marcas</h5>
                                <ul v-for="(item, index) in namebrands">
                                    <li>
                                        <div class="flex align-items-center">
                                            <RadioButton v-model="brandfilter" :inputId="`brand${index}`" :name="item" :value="`${item}`" style="border-radius: 10px; border: 1px solid #ffbf00"/>
                                            <label :for="`brand${index}`" class="ml-2">{{ item }}</label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="widget wiget-price" v-if="false">
                                <h5 class="title" style="font-family: Verdana;">precio($)</h5>
                                <div class="amount-cover">
                                    $<input type="text" placeholder="min" id="amount-min" v-model="rangeprice[0]" style="background: #fff; border: 2px solid #ffbf00">
                                    <span>&mdash;</span>
                                    <input type="text" placeholder="max" id="amount-max" v-model="rangeprice[1]" style="background: #fff; border: 2px solid #ffbf00">
                                </div>
                            </li>
                        </ul>
                        <a class="reset-filter-btn"  @click="resetfilters" style="cursor: pointer; font-family: Verdana;">Reestablecer filtros</a>
                    </div>
                </div>
                <div class="products-panel"><!--Resultado total de busqueda-->
                    <h2 class="title">resultados</h2>
                    <div class="shop-sort-cover">
                        <div class="sort-left">{{ startProduct < quantSearch ? startProduct : quantSearch }} de {{ quantSearch }} productos encontrados</div>
                        <div class="sort-right">
                            <div class="sort-by">
                                <span class="sort-name">Mostrar:</span>
                                <select class="nice-select" v-model="showproduct">
                                    <option selected="selected" value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>&nbsp; 
                            <div class="sort-by">
                                <span class="sort-name">Ordenar por:</span>
                                <select class="nice-select" v-model="orderby">
                                    <option selected="selected" value="0">Recomendado</option>
                                    <option value="1">Precio más bajo</option>
                                    <option value="2">Precio más alto</option>
                                </select>
                            </div>
                            <ul class="sort-form">
                                <li data-atr="block" class="active" @click="layout = 'grid'"><i class="fa fa-th" aria-hidden="true"></i></li>
                                <li data-atr="list" @click="layout = 'list'"><i class="fa fa-list" aria-hidden="true"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div class="shop-product-cover">
                        <DataView :value="products" :layout="layout" :rows="showproduct">
                            <template #list="slotProps">
                                    <div class="container-list mt-2">
                                        <div class="fixed-width">
                                            <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" style="width:100%;" @click="top">
                                                <img class="w-9 sm:w-16rem xl:w-10rem block xl:block mx-auto border-round" :src="slotProps.data.image" :alt="slotProps.data.name" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'" style="filter: brightness(1.1); mix-blend-mode: multiply;"/>
                                            </router-link>
                                        </div>
                                        <div class="flexible" style="font-family: Verdana;">
                                            <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" style="width:100%;" @click="top">
                                                <div class="text-2xl font-bold text-900">{{ slotProps.data.title }}</div>
                                            </router-link>
                                            <div class="text-700" style="font-size: 18px;">{{ slotProps.data.model }}</div>
                                            <Rating :modelValue="slotProps.data.rating" readonly :cancel="false"></Rating>
                                            <div class="flex align-items-center gap-3">
                                                <span class="flex align-items-center gap-2">
                                                    <i class="pi pi-tag"></i>
                                                    <span class="font-semibold">{{ slotProps.data.category }}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="fixed-width">
                                            <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                                <span>Añadir a cotización</span>
                                                <span class="text-2xl font-semibold" v-if="false">${{ (slotProps.data.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN</span>
                                                <a v-show="slotProps.data.quantity > 1" style="cursor: pointer;" class="btn btn-wishlist" @click="addCart(slotProps.data)">
                                                    <span>
                                                        <i class="pi pi-cart-plus" style="font-size: 2rem; color: #000;"></i>
                                                    </span>
                                                </a>
                                                <router-link v-show="slotProps.data.quantity < 1" @click="top()" :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" class="btn">Ver producto</router-link>
                                            </div>
                                        </div>
                                    </div>
                            </template>

                            <template #grid="slotProps">
                                <div class="col-10 col-sm-4 prod-item-col" style="margin-bottom: 5px;">
                                    <div class="product-item">
                                        <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" @click="top"  class="product-img">
                                            <img :src="slotProps.data.image" :alt="slotProps.data.model" style="filter: brightness(1.1); mix-blend-mode: multiply;" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'">
                                        </router-link>
                                        <div class="product-item-wrap">
                                            <div class="product-item-cover" style="font-family: Verdana;">
                                                <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" @click="top">
                                                <div class="price-cover" v-if="false">
                                                    <div class="new-price" style="font-family: Helvetica;">${{ (slotProps.data.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN </div>
                                                </div>
                                                <h6 class="prod-title" style="font-family: inherit;">{{ (slotProps.data.title.trim()) }}-"{{ slotProps.data.model }}"</h6></router-link>
                                                <button 
                                                    v-show="slotProps.data.quantity > 1"
                                                    class="btnProduct"
                                                    @click="addCart(slotProps.data)"
                                                > 
                                                    añadir
                                                    <i class="pi pi-cart-plus" style="color: #000;"></i>
                                                </button>
                                                <router-link v-show="slotProps.data.quantity < 1" @click="top()" :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" class="btn">Ver producto</router-link>
                                            </div>
                                            <div class="prod-info">
                                                <ul class="prod-list">
                                                    <li>Marca: <span>{{ slotProps.data.brand }}</span></li>
                                                    <li>Modelo: <span>{{ slotProps.data.model }}</span></li>
                                                    <li>Peso: <span>{{ slotProps.data.weight }} Kg</span></li>
                                                    <li>Tipo: <span>{{ slotProps.data.volume }} cm³</span></li>
                                                    <li>Categoría: <span>{{ slotProps.data.category }}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DataView>
                        <div>
                            <div ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-show="viewportWidth < 1024"><!--Cuando sea para menos de 1024px-->
                <div class="products-panel">
                    <h2 class="title">resultados</h2>
                    <div class="shop-sort-cover">
                        <div class="sort-left">{{ startProduct }} de {{ quantSearch }} prod., encontrados</div>
                        <div class="sort-right">
                            <div class="sort-by">
                                <span class="sort-name">Mostrar:</span>
                                <select class="nice-select" v-model="showproduct">
                                    <option selected="selected" value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>&nbsp; 
                            <div class="sort-by">
                                <span class="sort-name">Ordenar por:</span>
                                <select class="nice-select" v-model="orderby">
                                    <option selected="selected" value="0">Recomendado</option>
                                    <option value="1">Precio más bajo</option>
                                    <option value="2">Precio más alto</option>
                                </select>
                            </div>
                            <ul class="sort-form">
                                <li data-atr="block" class="active" @click="layout = 'grid'"><i class="fa fa-th" aria-hidden="true"></i></li>
                                <li data-atr="list" @click="layout = 'list'"><i class="fa fa-list" aria-hidden="true"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div class="shop-product-cover">
                        <DataView :value="products" layout="list" :rows="showproduct">
                            <template #list="slotProps">
                                <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" style="width:100%;" @click="top">
                                    <img class="w-9 sm:w-16rem xl:w-10rem block xl:block mx-auto border-round" :src="slotProps.data.image" :alt="slotProps.data.name" onerror="this.src='https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.jpg'"/>
                                </router-link>
                                <div class="container-list2 mt-2">
                                    <div class="flexible">
                                        <router-link :to="`/producto/${slotProps.data.brand}/${slotProps.data.url}`" style="width:100%;" @click="top">
                                            <div class="text-2xl font-bold text-900">{{ slotProps.data.title }}</div>
                                            <div class="flex align-items-center gap-3">
                                                <div class="text-700" style="font-size: 18px;">{{ slotProps.data.model }}</div>
                                                <span class="flex align-items-center gap-2">
                                                    <i class="pi pi-tag"></i>
                                                    <span class="font-semibold">{{ slotProps.data.category }}</span>
                                                </span>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="mx-auto mb-1">
                                    <span class="text-2xl font-semibold" v-if="false">${{ (slotProps.data.cost).toLocaleString('es-MX',{minimumFractionDigits: 2,maximumFractionDigits: 2,}) }} MXN </span>&nbsp;&nbsp;
                                    <a v-show="slotProps.data.quantity > 1" class="btn" style="cursor: pointer;" @click="addCart(slotProps.data)" :disabled="slotProps.data.quantity < 1"><span>Añadir al carrito</span></a>
                                    <router-link v-show="slotProps.data.quantity < 1" @click="top()" :to="`/producto/${slotProps.data.brand}/${slotProps.data.model}`" class="btn">Ver producto</router-link>
                                </div>
                                <Divider type="solid" class="mb-1"/>
                            </template>
                        </DataView>
                    </div>
                </div>
            </div>
            <div>
                
            </div>
            <div style="display:flex; justify-content: end;">
                <button
                    v-if="pagination > 1"
                    :class="{ 'btnpaginator': true, 'active': pagination === 1 }"
                    @click="changePage(1)"
                >
                    <i class="pi pi-angle-double-left" style="font-size: 1rem; color: #000;"></i>
                    <span></span>
                </button>
                <button class="btnpaginator" style="margin-right: 5px;" v-if="pagination > 1" @click="changePage(pagination - 1)">
                    <i class="pi pi-angle-left" style="font-size: 1rem; color: #000;"></i>
                    <span></span>
                </button>

                <button class="btnpaginator" 
                    style="margin-right: 5px;" 
                    v-for="pageNumber in visiblePages" 
                    :class="{ 'btnpaginator': true, 'active': pagination === pageNumber }"
                    :key="pageNumber" 
                    @click="changePage(pageNumber)"
                >
                {{ pageNumber }}<span></span>
                </button>

                <button class="btnpaginator" 
                    style="margin-right: 5px;" 
                    v-if="pagination < totalPages" 
                    :class="{ 'btnpaginator': true, 'active': currentPage === totalPages }" 
                    @click="changePage(pagination + 1)"
                >
                    <i class="pi pi-angle-right" style="font-size: 1rem; color: #000;"></i>
                    <span></span>
                </button>
                <button
                    v-if="pagination < totalPages"
                    :class="{ 'btnpaginator': true, 'active': pagination === totalPages }"
                    @click="changePage(totalPages)"
                >
                    <i class="pi pi-angle-double-right" style="font-size: 1rem; color: #000;"></i>
                    <span></span>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import { ref } from 'vue';
import { sliderService } from '../services/sliderService.js';
import { ApiService } from '@/services/ApiService';
import { ImageService } from '@/services/ImageService';
export default {
    props:{},
    components:{
        BannerBlue,
    },
    created(){
        window.addEventListener("resize", this.updateViewportWidth);//Para tomar el tamaño de la pantalla
        this.updateSearch();
    },
    beforeUnmount(){
        window.removeEventListener("resize", this.updateViewportWidth);
    },
    data(){
        return{
            nametitle: ref('buscar'),
            params: ref({
                one: '',
                two: '',
            }),
            siderFilters: false,
            productsOri: null,
            products: null,
            layout: 'grid',
            quantSearch: 0,
            orderby: 0,
            showproduct: 15,
            viewportWidth : ref(window.innerWidth),
            namebrands: ref([]),
            brandfilter: '',
            rangeprice: [null, null],
            pagination: 1,
            totalPagi: 1,
            startProduct: 0,
            numPagin: [1,2,3,4,]
        };
    },
    watch:{
        '$route.params.fparam': function(newData, oldData){
            this.resetfilters();
        },
        showproduct(nvoVal, oldVal){
            this.showproduct = Number(nvoVal)
            this.updateSearch();
        },
        orderby(nvoVal,oldVal){//Función para ordena por:
            this.updateSearch();
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalPagi / this.showproduct);
        },
        visiblePages() {
            var startPage = Math.max(1, this.pagination - 2);
            var endPage = Math.min(this.totalPages, startPage + 3);
            return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
        },
    },
    methods:{
        updateSearch(){
            this.params.one = this.$route.params.fparam;
            if (this.params.one !== undefined){
                this.params.one = this.params.one.includes("+") ? (this.params.one).replace(/\+/g, "/") : this.params.one;
                if(this.$route.params.sparam != null){
                    this.params.two = this.$route.params.sparam;
                    this.nametitle = this.params.two;
                }else if (this.params.one != null) {
                    this.nametitle = this.params.one;
                }
            }
            
            this.getData();
        },
        changePage(pageNumber) {
            // Aquí puedes realizar acciones adicionales al cambiar de página
            this.pagination = pageNumber;
            this.getData();
        },
        async getData(){
            try {
                var min = 0;
                var max = 100000000;
                if(this.rangeprice[0] != null && this.rangeprice[1] != null && this.rangeprice[0] > 0 && this.rangeprice[0] > 0){
                    if(this.rangeprice[0] < this.rangeprice[1]){
                        min = this.rangeprice[0];
                        max = this.rangeprice[1];
                    }else{
                        new Notify({
                            status: 'error',
                            title: 'Error',
                            text: 'El filtro del precio minimo debe ser menor al máximo',
                            effect: 'fade',
                            speed: 300,
                            customClass: null,
                            customIcon: false,
                            showIcon: true,
                            showCloseButton: true,
                            autoclose: true,
                            autotimeout: 3000,
                            gap: 20,
                            distance: 20,
                            type: 2,
                            position: 'right top'
                        })
                        this.rangeprice[0] = null;
                        this.rangeprice[1] = null;
                    }
                    
                }
                var getsql = "/shop?search=" + this.nametitle + "&cant=" + this.showproduct + "&pag=" + this.pagination + "&order=" + this.orderby + "&brand=" + this.brandfilter + "&min=" + min + "&max=" + max + ""
                await ApiService.get(getsql)//Llamamos al Service de las apis para traer datos en get
                    .then(async data => {
                        if (data.length > 0) {
                            this.namebrands = data[0].brands;
                            this.startProduct = data[0].start;
                            this.quantSearch = data[0].total_cant;
                            this.totalPagi = this.quantSearch;
                            this.products = await ImageService.updateUrl(data[0].res);
                        }
                    })
                    .catch(error => {
                        console.error('Error en la solicitud:', error.message);
                    });
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        addCart(jprod){
                var jProduct = {
					image : jprod.image,
					alt   : jprod.alt,
					title : jprod.title,
					model : jprod.model,
					brand : jprod.brand,
					cost  : jprod.cost,
					promotion : jprod.promotion,
					quantity  : 1,
				};
				this.$store.dispatch('findAndUpdateProduct', jProduct);
                localStorage.setItem('jproducts',JSON.stringify(this.$store.getters.getProducts));
				//Toast libreria Simple Notify
				new Notify({
					status: 'success',
					title: 'Agregado',
					text: 'El producto '+jprod.title+' se ha agregado al carrito',
					effect: 'fade',
					speed: 300,
					customClass: null,
					customIcon: false,
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					gap: 20,
					distance: 20,
					type: 2,
					position: 'right top'
				})
				//console.log(this.$store.getters.getProducts);
			},
        resetfilters(){
            this.brandfilter = '';
            this.rangeprice[0] = null;
            this.rangeprice[1] = null;
            this.updateSearch();
        },
        aplyfilters(){
            this.updateSearch();
        },
        top(){
            window.scrollTo(0, 0);
        },
        updateViewportWidth() {
            this.viewportWidth = window.innerWidth;
        },
    }
}
</script>

<style scoped>
.btnProduct {
 padding: 0.8em 1.8em;
 border: 2px solid #000000;
 position: relative;
 overflow: hidden;
 background-color: transparent;
 text-align: center;
 text-transform: capitalize;
 font-size: 16px;
 transition: .3s;
 z-index: 1;
 font-family: inherit;
 color: #000;
}

.btnProduct::before {
 content: '';
 width: 0;
 height: 700%;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%) rotate(45deg);
 background: #FECA31;
 transition: .5s ease;
 display: block;
 z-index: -1;
}

.btnProduct:hover::before {
 width: 105%;
}

.btnProduct:hover {
 color: #111;
}

.btnpaginator {
  border: none;
  border-radius: 10px;
  display: block;
  position: relative;
  padding: 0.7em 1.1em;
  font-size: 18px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: #000;
  z-index: 1;
  font-family: inherit;
  font-weight: 500;
}

.btnpaginator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
  border-radius: 10px;
  border: 4px solid #ffbf00;
}

.btnpaginator span::before {
  content: "";
  display: block;
  position: absolute;
  width: 8%;
  height: 500%;
  background: var(#FFBF00);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-60deg);
  transition: all 0.3s;
}

.btnpaginator:hover span::before {
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 100%;
  background: #FFBF00;
}

.btnpaginator:hover {
  color: rgb(0, 0, 0);
}

.btnpaginator:active span::before {
  background: #dda602;
}
.btnpaginator.active {
  background-color: #ffbf00; /* Color de fondo para el botón activo */
  color: white; /* Color de texto para el botón activo */
}

.btnpaginator.active span {
  border-color: #ffbf00; /* Color del borde para el botón activo */
}

.btnpaginator.active span::before {
  background:#ffbf00; /* Color del triángulo para el botón activo */
}

.btnpaginator.active:hover span::before {
  background: #ffbf00; /* Color del triángulo al pasar el ratón sobre el botón activo */
}

.btnpaginator.active:active span::before {
  background: #dda602; /* Color del triángulo al hacer clic en el botón activo */
}
.containerCart{
	padding: 10px;
}
.btnfilter{
    text-transform: none;
    cursor: pointer;
    color:#000;
}
.container-products {
    display: grid;
    grid-template-columns: 250px 1fr; /* El primer panel tiene 400px de ancho y el segundo ocupa el espacio restante */
    grid-gap: 10px; /* Espacio entre los paneles (ajusta según tus necesidades) */
    margin-bottom: 5px;
}

.filters-panel {
    background-color: rgba(240, 240, 240, 0.726); /* Color de fondo del panel izquierdo */
    padding: 20px; /* Margen interior (ajusta según tus necesidades) */
    text-align: start;
}

.products-panel {
    padding: 10px; /* Margen interior (ajusta según tus necesidades) */
}
.prod-item-col{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container-list2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 10px 10px;
}
.container-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 10px 10px;
    border-bottom: 3px solid rgba(224, 224, 224, 0.418);
}
.fixed-width {
    width: 200px;
    text-align: center;
    padding: 10px;
}   
.flexible {
    flex: 1; /* Se adapta al tamaño disponible */
    text-align: start;
    padding: 10px;
}
.container-list:hover{
    box-shadow: 10px;
    background: rgba(224, 224, 224, 0.418);
    border-bottom: 3px solid rgba(224, 224, 224, 0);
}
</style>