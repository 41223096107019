<template>
    
    <div class="custom-slider">
        <div class="slide" :key="currentIndex" v-if="slides.length > 0">
            <img :src="currentSlide.image" :alt="currentSlide.alt" />
        </div>
        <button @click="prevSlide" class="btn-slider">
            <svg fill="#000000" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&amp;ns_extend;" xmlns:i="&amp;ns_ai;" xmlns:graph="&amp;ns_graphs;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" width="30px" height="30px" viewBox="0 0 42 42" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon fill-rule="evenodd" points="31,38.32 13.391,21 31,3.68 28.279,1 8,21.01 28.279,41 "></polygon> </g></svg>
        </button>
        <button @click="nextSlide" class="btn-slider">
            <svg fill="#000000" version="1.1" baseProfile="tiny" id="Layer_1" xmlns:x="&amp;ns_extend;" xmlns:i="&amp;ns_ai;" xmlns:graph="&amp;ns_graphs;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" width="30px" height="30px" viewBox="0 0 42 42" xml:space="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon fill-rule="evenodd" points="31,38.32 13.391,21 31,3.68 28.279,1 8,21.01 28.279,41 "></polygon> </g></svg>
        </button>
    </div>
</template>

<script>
import { ref } from "vue";
import { ApiService } from '@/services/ApiService.js';
import { sliderService } from '@/services/sliderService';

export default {
    props:{
        codprod: String,
        brandprod: String,
    },
    data() {
        return {
            currentIndex: 0,
            slides: ref([]),
            vproduct: ref({
                code : '',
                brand: '',
            }),
        };
    },
    computed: {
        currentSlide() {
            if (this.slides.length === 0) {
                return {image: '../assets/img/noprod.png'}; // Otra opción es manejar un mensaje o contenido predeterminado si no hay slides.
            }
            return this.slides[this.currentIndex];
        },
    },
    mounted() {
        sliderService.getImagesSlider().then((data) => (this.slides = data));
        this.vproduct.code = this.codprod;
        this.vproduct.brand = this.brand;
        //ApiService.get("/sliderProduct", this.vproduct).then((data) => (this.slides = data));//Ya está el llamado a la API
    },
    methods: {
        prevSlide() {
            if (this.slides.length === 0) return;
            this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
        },
        nextSlide() {
            if (this.slides.length === 0) return;
            this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        },
    },
};
</script>

<style scoped>
.btn-slider{
    width: 40px;
}
.custom-slider {
    text-align: center;
    margin: 20px 0;
}

.slide {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-content {
    text-align: left;
    padding: 20px;
}

.slide img {
    max-width: 100%;
    max-height: 100%;
}

.btn-slider {
    background: none;
    border: 2px solid #EEC815;
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    width: 60px;
    border-radius: 2px;
    transition: background-color 0.5s ease;
}

.btn-slider:hover {
    background: #EEC815;
}
</style>