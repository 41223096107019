<template>
    <footer>
		<div class="container">
			<div class="row footer-item-cover">
				<div class="footer-subscribe col-md-7 col-lg-8">
					<h6 style="text-align: start;">suscríbete </h6>
					<p style="text-align: start;">Suscríbete para recibir nuestras ofertas</p>
					<div class="subscribe-form">
						<i class="fa fa-at" aria-hidden="true"></i>
						<input class="inp-form" type="email" name="subscribe" placeholder="Correo" v-model="emailSus">
						<button type="submit" class="btnSuscribe" @click="suscribe()"><span>ENVIAR</span></button>
					</div>
				</div>
				<div class="footer-item col-md-5 col-lg-4" style="text-align: start;">
					<h6>info</h6>
					<ul class="footer-list">
						<li><router-link to="/faq" @click="toTop">Preguntas frecuentas</router-link></li>
						<li><router-link to="/contacto" @click="toTop">Contácto</router-link></li>
						<li><router-link to="/terminos-y-condiciones" @click="toTop">Terminos y condiciones</router-link></li>
						<li><router-link to="/catalogos" @click="toTop">Catálogos</router-link></li>
						<li><router-link to="/nosotros" @click="toTop">Nosotros</router-link></li>
					</ul>
				</div>
			</div>
			<div class="row footer-item-cover">
				<div class="footer-touch col-md-7 col-lg-8">
					<h6 style="text-align: start;">Siguenos</h6>
					<ul class="footer-soc social-list">
						<li><a target="_blank" href="https://www.facebook.com/proveedorferreterogdl/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
						<li><a target="_blank" href="https://www.instagram.com/proveedorferreterogdl/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
						<li><a target="_blank" href="https://www.youtube.com/@proveedoferreterogdl"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
					</ul>
					<div class="footer-autor" style="text-align: start;">¿Dudas? Por favor comuniquese con: <a href="mailto:contacto@proveedorferretero.net">contacto@proveedorferretero.net</a></div>
				</div>
				<div class="footer-item col-md-5 col-lg-4" style="text-align: start;">
					<router-link to="/" class="logo"><img src="../assets/img/logo.svg" alt="Proveedor Ferretero de Guadalajara" style="width: 400px;"></router-link>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="footer-copyright">
					<router-link to="/nosotros" target="_blank">Proveedor Ferretero de Guadalajara</router-link> © 2023. Todos los derechos reservados. Realizado con <a href="https://www.heybaca.com" class="heartHey">&#10084;</a>
				</div>
				
			</div>
		</div>
	</footer>
	<Toast/>
</template>

<script>
import { ApiService } from '@/services/ApiService';
    export default {
		data(){
			return{
				emailSus:'',
			}
		},
        methods:{
			toTop(){
				window.scrollTo(0, 0);
			},
			async suscribe(){
			//ApiService.post("/suscribe", emailSus);//Ya está el llamado a la API
				if(this.emailSus != ''){
					var data = {
						"email": this.emailSus,
					}
					await ApiService.post("/suscribe", data)
						.then(async data => {
							if ('detail' in data){
								this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo suscribir', life: 3000 });
							}else{
								console.log(data);
								data = await ImageService.updateUrl(data); //Se llama el Service para actualizar el utl de la imagen 
								console.log(data);
								this.imgSale = data;
							}
						})
						.catch(error => {
							console.error('Error en la solicitud:', error.message);
						});
				}else{
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor ingrese el correo para suscribirse', life: 3000 });
				}
				
			},
		}
    }
</script>

<style scoped>
.heartHey:hover{
	color: #009e66;
}
.btnSuscribe {
	padding: 0.8em 1.8em;
	border: 2px solid #000;
	position: relative;
	overflow: hidden;
	background-color: transparent;
	text-align: center;
	text-transform: uppercase;
	font-size: 16px;
	transition: .3s;
	z-index: 1;
	
	color: #000;
}

.btnSuscribe::before {
	content: '';
	width: 0;
	height: 300%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	background: #FECA31;
	transition: .5s ease;
	display: block;
	z-index: -1;
}

.btnSuscribe:hover::before {
 	width: 105%;
}

.btnSuscribe:hover {
 	color: #000000;
}
</style>